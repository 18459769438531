@import "frontend_base/style-components/definitions"

#logout
    position: absolute
    top: 4vw
    right: 4vw
    display: inline-block
    width: 1.25em
    height: 1.25em
    background-image: url(../node_modules/frontend_base/icons/logout.svg)
    background-size: contain
    padding-bottom: 0

.bg-warning
    background-color: rgba(255, 120, 0, .8) // #ff7800

.bg-warning:nth-child(odd)
    background-color: rgba(255, 120, 0, .9) // #ff7800

.bg-error
    background-color: rgba(228, 35, 34, .9) // #e42322


a.pagination
    display: inline-block
    width: 1.2rem
    height: 1em
    vertical-align: middle
    background-repeat: no-repeat
    background-size: contain
    box-sizing: border-box
    padding: .25em
    &.previous
        background-image: url(../node_modules/frontend_base/icons/arrow-left.svg)
        background-position: left center
    &.next
        background-image: url(../node_modules/frontend_base/icons/arrow-right.svg)
        background-position: right center

td[title="total_revenue"]
    text-decoration-line: underline
    text-decoration-style: double

.min-width-graph-container
    width: 520px
    max-width: 75%

.medium-width-graph-container
    width: 1040px
    max-width: 100%

.max-width-graph-container
    width: 1560px
    max-width: 100%

.graph-wrapper
    float: left

form
    max-width: 1561px


    //---

\:root
    --global-font-color: #5b5b5b
    --global-font-family: "Jost","Futura",sans-serif
    --global-font-size: 18
    --global-font-line-height: 1.4
    --highlight-font-family: "Jost","Helvetica Neue","Arial Narrow",sans-serif
    --decorative-font-family: "Cardenio Modern","Helvetica Neue","Arial Narrow",sans-serif
    --container-width: 75rem
    --content-width: 45rem
    --dropdown-symbol-width: 0.7em
    --global-margin: 1rem
    --global-padding: 1rem
    --global-content-padding: 1.25rem 1.875rem
    --global-box-shadow: 1px 3px 5px 0 rgba(0,0,0,0.3)
    --global-box-shadow-large: 1px 3px 15px 0 rgba(0,0,0,0.3)
    --global-border-radius-small: 0.75rem
    --global-border-radius: 1.75rem
    --global-input-padding: 0.75rem 1.875rem
    --global-input-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.2)
    --expandable-search-size-compact: 3.175rem
    --expandable-search-size-expanded: 13rem
    --expandable-search-size-wide: 15rem
    --color-theme-primary: #1b7340
    --color-theme-secondary: #90d3ed
    --color-theme-red: #c0392b
    --color-theme-green: #1da64a
    --color-theme-blue: #00a0d2
    --color-theme-yellow: #f1c40f
    --color-theme-black: #000
    --color-theme-grey: #95a5a6
    --color-theme-white: #fff
    --color-theme-smoke: #ecf0f1
    --color-link: #1b7340
    --color-link-visited: #14532e
    --color-link-active: var(--color-theme-blue)
    --color-quote-border: #000
    --color-quote-citation: #6c7781
    --border-color-dark: #333
    --border-color-light: #ccc
    --font-size-small: calc(16/var(--global-font-size)*1rem)
    --font-size-regular: calc(var(--global-font-size)/16*1rem)
    --font-size-medium: calc(26/var(--global-font-size)*1rem)
    --font-size-mediumlarge: calc(32/var(--global-font-size)*1rem)
    --font-size-large: calc(42/var(--global-font-size)*1rem)
    --font-size-larger: calc(60/var(--global-font-size)*1rem)
    --font-size-largest: calc(86/var(--global-font-size)*1rem)


h1,h1 a,h2,h2 a,h3,h3 a,h4,h4 a,h5,h5 a,h6,h6 a
    font-family: var(--highlight-font-family), Jost,Helvetica Neue,Arial Narrow,sans-serif

body,button,input,optgroup,select,textarea
    color: var(--global-font-color)
    font-family: var(--global-font-family), Jost,Futura,sans-serif
    font-size: var(--font-size-regular)
    font-weight: 400
    line-height: var(--global-font-line-height)

.is-style-highlight
    font-family: var(--highlight-font-family), Jost,Helvetica Neue,Arial Narrow,sans-serif
    font-weight: 600


h1:before
    background-image: url(img/me-logo-2-400.png)
    //content: attr(href)
    height: 3em
    margin-top: -1.0em
    //border: 1px solid red

h1 a
    text-decoration: none

h1 a:hover
    text-decoration: underline

body::after
    content: ""
    background-image: url(img/me-logo-2-400.png)
    background-repeat: no-repeat
    background-size: 800px
    background-position-x: right
    background-position-y: top
    opacity: 0.1
    top: 0
    left: 0
    bottom: 0
    right: 0
    position: absolute
    z-index: -1

div.md
    .graph-wrapper
        float: unset
    img
        max-width: 100%
    hr
        max-width: 62em
    main
        margin: 0 auto
        text-align: left
        max-width: 62em
    header
        margin: 0 auto
        text-align: left
        max-width: 62em

embetty-video
    width: 360px
    height: 200px
    display: inline-block

nav.pagination
    padding: 0


#powered-by
    display: none !important

nav#nav-main
    ul, li
        margin: 0.0em
        display: -webkit-flex
        display: flex
        flex-wrap: wrap
    li
        -webkit-flex: 1
        flex: 1
        margin: 0 0.5em

footer

    background-color: #111
    font-size: smaller
    display: flex
    justify-content: space-around
    padding: 0.5em 0
    div, p, a
        color: #ccc
        display: flex
        flex-direction: column
        align-items: center

#this-is-us
    table, tr, td
        border: none

    td
        vertical-align: top
        margin: 0
        padding: 0 0 1em 0

    .us-img
        width: 15em
        padding-right: 1em


table#this-is-us
    tr
      background: none
